// import { dti } from 'dom-to-image';
import { ViewsComponent } from './../../components/modals/views/views.component';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, ElementRef, OnDestroy, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { GridType, CompactType, GridsterConfig, GridsterItem, DisplayGrid } from 'angular-gridster2';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { ExpandedWidgetComponent } from 'src/app/components/modals/expanded-widget/expanded-widget.component';
import { GlobalFunctionService } from 'src/app/services/global-function/global-function.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { DashboardFilterComponent } from '../manage/dashboard/dashboard-filter/dashboard-filter.component';
import { DashboardSelectionComponent } from 'src/app/components/dashboard-selection/dashboard-selection.component';
import { ShareFormComponent } from 'src/app/components/modals/share-form/share-form.component';
import { MessagesComponent } from 'src/app/components/modals/messages/messages.component';
import { gridTypes } from 'angular-gridster2/lib/gridsterConfig.interface';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { CoreService } from 'src/app/services/core/core.service';
import { interval, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { skip, takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetService } from 'src/app/services/widget/widget.service';
import { ShareService } from 'src/app/services/share/share.service';
import { Share } from 'src/app/models/responses/shareResponse';
import { cerateCssFile, deleteCssFile, idKeyword } from 'src/app/core/utils';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';
import { FilterAiService } from 'src/app/services/filter-ai/filter-ai.service';
import { ChatbotComponent } from 'src/app/components/chatbot/chatbot.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatbotService } from 'src/app/services/chatbot/chatbot.service';
import html2canvas from 'html2canvas';
import { Widget, WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';



import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { Montserrat } from 'src/assets/fonts/Montserrat';
import { MontserratBold } from 'src/assets/fonts/MontserratBold';
import PptxGenJS from 'pptxgenjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DashboardPerformanceInfoComponent } from 'src/app/components/dashboard-performance-info/dashboard-performance-info.component';
// import JSPDF from 'jspdf';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild('dashboardPage')
  dashboardPage!: ElementRef;
  @ViewChild('sidenav') sidenav: any
  @ViewChild('viewsModal') viewsModal: any;
  @ViewChild('filterModal') filterModal: any;
  isFilterModal: boolean = false
  isShownFilter: boolean = false;
  isShownFilterAI: boolean = false
  isDeviceMobile: boolean = false;
  isDeviceTablet: boolean = false;
  showMessages = false;
  baseImageUrl: string = environment.imageUrl;
  isFilterActive: boolean = false;
  isAutoPlaying: boolean = false;
  isFullscreen: boolean = false;
  dashboardType: string = "fit";
  dashboards: Dashboard[] = [];
  authorizedDashboards: Dashboard[] = [];
  options: GridsterConfig;
  dashboardWidgets: Array<GridsterItem> = [];
  serviceWidgets: WidgetPreview[] = []
  selectedDashboard: Dashboard;
  permissions: string[] = [];
  checkDashboardInterval = interval(30000);
  subscriptionCheckDashboardInterval: Subscription;
  dashboardChangesId: string = "";
  isFirstForCheckDashboardData: boolean = true;
  isDashboardHeaderVisible = true;

  selectedDashboardInitialFilters: BussionFilterDefinition[] = [];
  sugesstionFilter: BussionFilterDefinition[] = [];
  infoDialogRef: MatDialogRef<DashboardPerformanceInfoComponent> | null = null;




  autoPlayDashboardInterval = interval(60000);
  subscriptionAutoPlayDashboardInterval: Subscription;
  displayDropDown: boolean = false
  dropdownData: any
  myShares: Share[] = [];
  filterLoading: boolean = true

  permissionPath: string = '';
  button = true
  button_ = true
  fullscreen: boolean


  userInput: string = "";
  dashboardId: any
  @Input() appId: string = ''
  redirectApp: string = ''
  private unsubscribe = new Subject();

  @ViewChild('gridcontainer') screen: any;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  @Output() returnApp: EventEmitter<any> = new EventEmitter<any>();
  visibleInfoBox: boolean = false

  onClick(event) {

    this.displayDropDown = false
  }

  constructor(
    private dashboardService: DashboardService,
    private scriptLoader: ScriptLoaderService,
    private globalFunctionService: GlobalFunctionService,
    private modalService: ModalService,
    private permissionService: PermissionService,
    private coreService: CoreService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    private widgetService: WidgetService,
    private authService: AuthService,
    private shareService: ShareService,
    private filterAIService: FilterAiService,
    private dialog: MatDialog,
    private dialogService: ModalService


  ) {
    this.selectedDashboard = router.getCurrentNavigation()?.extras?.state as Dashboard
  }
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[3]);
  contentIdKeyword = idKeyword.dashboard

  ngOnInit(): void {
    this.customizeDashboard();
    if ((this.appId && this.appId == '') || this.route.snapshot.paramMap.get('appId'))
      this.route.params.subscribe(params => {
        if (params['appId']) {
          this.appId = params['appId'];
          this.redirectApp = '/data-app/' + this.appId
        }
      });



    this.handleSelectedDashboard();

    this.getUserLibraries();
    this.isDeviceMobile = this.deviceService.isMobile();
    this.isDeviceTablet = this.deviceService.isTablet();
    this.dropdownData = {
      objectId: this.selectedDashboard.dashboardId,
      objectType: 'Dashboard',
      dashboardId: this.selectedDashboard.dashboardId,
      filter: this.selectedDashboard.filter,
    };
    if (this.selectedDashboard.css !== "") {
      cerateCssFile(this.selectedDashboard.css, this.selectedDashboard.dashboardId, this.contentIdKeyword);
    }


    this.getMyShares()

    // this.changeGridOptions()
    if (this.selectedDashboard) {
      this.dashboardService.selectedDasboard.next(this.selectedDashboard)

      // this.dashboardWidgets = [];
      // this.getDashboardsWidgets(this.selectedDashboard.dashboardId);
      // console.log(this.selectedDashboard)

    }

    var roleList = this.getUserRoles()
    if (roleList.includes('Admin')) {
      this.visibleInfoBox = true
    }


  }


  changeGridOptions() {
    if (this.selectedDashboard.gridSize) {
      this.options.maxCols = this.selectedDashboard.gridSize;
      this.options.minCols = this.selectedDashboard.gridSize;

    }

    this.changeDashboardLayout(this.selectedDashboard.layout)

    this.options?.api?.optionsChanged()
  }


  getFilteredDashboard(text: string) {
    if (text.length === 0) {
      this.scriptLoader.setFilter(this.selectedFilters)
      return;
    }

    this.filterAIService.getSuggestionForDashboard(text, this.selectedDashboard.dashboardId).subscribe((res) => {

      res[0].dashboard.filter.map(item => {

        if (!this.sugesstionFilter.some(x => x.fieldValue == item.fieldValue)
          && !this.selectedDashboardInitialFilters.some(x => x.fieldValue == item.fieldValue)) {
          this.sugesstionFilter.push(item)
        }
      })


      this.scriptLoader.setFilter(this.sugesstionFilter)
      // this.getDashboardForFilter(this.selectedDashboard.dashboardId, res[0].dashboard.filter)


    });

  }



  shortTitle(maxLength: number): string {
    if (this.selectedDashboard.title.length > maxLength) {
      return this.selectedDashboard.title.substring(0, maxLength) + '...';
    }
    return this.selectedDashboard.title;
  }

  toggleDashboardHeaderVisibilityMobile(): void {
    this.isDashboardHeaderVisible = !this.isDashboardHeaderVisible;
    this.fullscreen = !this.fullscreen
  }

  toggleDashboardHeaderVisibility(): void {
    this.isDashboardHeaderVisible = !this.isDashboardHeaderVisible;
    this.button = !this.button
    this.fullscreen = !this.fullscreen
  }

  changeinitFilter() {
    this.selectedDashboardInitialFilters = Object.assign([], this.selectedDashboard.filter.slice());

  }


  handleSelectedDashboard() {

    if (this.selectedDashboard) {
      sessionStorage.setItem('selectedDashboard', JSON.stringify(this.selectedDashboard));
    } else {
      this.selectedDashboard = JSON.parse(sessionStorage.getItem('selectedDashboard'))
      this.dashboardService.selectedDasboard.next(this.selectedDashboard)
      this.changeinitFilter()
    }
    this.changeGridOptions()

  }



  ngOnDestroy(): void {
    if (this.subscriptionCheckDashboardInterval) {
      this.subscriptionCheckDashboardInterval.unsubscribe();
    }
    if (this.subscriptionAutoPlayDashboardInterval) {
      this.subscriptionAutoPlayDashboardInterval.unsubscribe();
    }
    deleteCssFile(this.selectedDashboard.dashboardId)
    this.scriptLoader.selectedFilters.next([])

    this.unsubscribe.next();
    this.unsubscribe.complete();


  }

  private getUserLibraries() {
    this.dashboardService.getUserLibraries().subscribe(result => {
      // console.log("Libraries: ", result);
      this.scriptLoader.addScriptFileToHead(result);
      this.setupPage();
    })
  }

  private setupPage() {
    // this.customizeDashboard();
    this.getAuthorizedDashboards();
    this.getGlobalFunctions();
    this.getPermissionForMainPage();
    this.listenFilterClick();
    this.listenDashboardNavigateEvent();
    this.scriptLoader.selectedFilters.subscribe(res => {
      // if (res.length > 0) {
      //   this.filterGroup(res)
      // } else {
      this.dimensionValuTypeControl()
      this.createPinToFilter(res)

      // }
      this.selectedFilters = res
      this.sugesstionFilter = res
    })
  }

  dimensionValuTypeControl() {
    this.selectedDashboard.dimensions.map(dim => {
      if (dim.type.includes('String') && dim.value.some(x => typeof (x) !== 'string')) {
        dim.value = dim.value.map(val => String(val));
      } else if (dim.type.includes('Number') && dim.value.some(x => typeof (x) !== 'number')) {
        dim.value = dim.value.map(val => +val);

      }
    })
  }

  createPinToFilter(initfilter: BussionFilterDefinition[]) {
    var selecteddimensiontofilter: Dimension[] = this.selectedDashboard.dimensions.filter(x => x.pintoPanel == 1)
    var bsf: BussionFilterDefinition[] = selecteddimensiontofilter.map(item => {
      return {
        fieldName: item.name,
        operator: '',
        fieldValue: ''
      }
    })
    this.filterGroup(initfilter.concat(bsf))
  }

  getUserRoles() {
    var user = localStorage.getItem('user')
    var userobj
    if (user) {
      userobj = JSON.parse(user)
    }
    if (userobj)
      return userobj.roles
    else
      return []
  }

  getPermissionForMainPage() {
    this.permissionService.getUserRoleList().subscribe(permissions => {
      this.permissions = permissions;
      console.log('this.permissions', this.permissions)
      this.permissionPath = this.permissionService.getPermissionPath();
    })
  }

  getGlobalFunctions() {
    this.globalFunctionService.getGlobalFunctions().subscribe(result => {
      result.forEach(f => {
        this.scriptLoader.runGlobalFunction(f.code);
      });

      this.getDashboards();
    });
  }

  getAuthorizedDashboards() {
    if (this.dashboardService.cachedData.getValue().authorizedDashboard.length > 0) {
      this.authorizedDashboards = this.dashboardService.cachedData.getValue().authorizedDashboard;

    } else
      this.dashboardService.getAuthorizedDashboards(1).subscribe(result => {
        this.authorizedDashboards = result;
      });
  }

  changeAppDashbboard(dashboardList: Dashboard[]) {
    //dashboards change too app dashboard if app id is not empty
    var app: any = localStorage.getItem('app')
    var appDashboards = []
    if (app) {
      app = JSON.parse(app)
      if (app.dashboards) {
        dashboardList.map(item => {
          if (app.dashboards.some(x => x == item.dashboardId)) {
            appDashboards.push({ ...item, tagList: item.tags.split(' ').filter(x => x !== '') })
          }
        })
      }
    }

    return appDashboards
  }


  getDashboards() {

    if (this.dashboardService.cachedData.getValue().activeDashboard.length > 0) {
      this.dashboards = this.dashboardService.cachedData.getValue().activeDashboard;

      if (this.appId !== '') {
        this.dashboards = this.changeAppDashbboard(this.dashboards)
      }

      if (this.dashboards.length !== 0) {
        this.changeinitFilter()

        let selectedDashboardId = this.selectedDashboard.dashboardId;
        this.dashboardWidgets = [];
        this.getDashboardsWidgets(selectedDashboardId);
      }


    } else {
      this.getActiveDashboard()
    }

  }
  getActiveDashboard() {
    this.dashboardService.getUserActiveDashboards(1).subscribe(result => {
      this.dashboards = result;

      if (this.appId !== '') {
        this.dashboards = this.changeAppDashbboard(this.dashboards)
      }

      if (this.dashboards.length !== 0) {

        // this.selectedDashboard = this.dashboards[this.lastDashboardIndex()];

        this.changeinitFilter()

        let selectedDashboardId = this.selectedDashboard.dashboardId;
        this.dashboardWidgets = [];

        this.getDashboardsWidgets(selectedDashboardId);
      }

    });
  }

  lastDashboardIndex(): number {
    let index = this.dashboards.findIndex(f => f.dashboardId == this.dashboardService.getLastDashboardId());
    // debugger;
    if (index == -1) {
      return 0;
    } else {
      return index;
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    // this.customizeDashboard()


    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      setTimeout(() => {

        // var rowcol = this.MobileWidgetSettings()

        // var currentY = 0
        // var currentX = 0



        // this.dashboardWidgets = this.dashboardWidgets.map((item, key) => {
        //   key % 2 == 0 && key !== 0 ? currentY += 30 : '' ? currentY += 30 : ''
        //   var newwd: any = {
        //     ...item,
        //     cols: this.isDeviceMobile || (this.isDeviceTablet && window.orientation == 0) ? rowcol.cols : item.designerContainer.widget.layout.cols,
        //     rows: this.isDeviceMobile || (this.isDeviceTablet && window.orientation == 0) ? rowcol.rows : item.designerContainer.widget.layout.rows,
        //     y: this.isDeviceTablet && window.orientation == 0 ? currentY : item.designerContainer.widget.layout.y,
        //     x: this.isDeviceTablet && window.orientation == 0 ? (currentX += 6) - 6 : item.designerContainer.widget.layout.x,
        //   }

        //   this.createWidget(newwd.designerContainer)
        //   return newwd
        // })


        this.widgetLayoutEdit(this.serviceWidgets, this.options)
      }, 250);

    }
  }

  widgetLayoutEdit(widgets, options) {

    this.dashboardWidgets = []
    let currentX = 0;
    let currentY = 0;

    var rowcol = this.MobileWidgetSettings()


    widgets.forEach((widget, index) => {
      if (widget.widget.isFilter == 0) {
        // index % 2 == 0 ? currentY += 30 : ''

        var newrows = widget.widget.layout.rows
        var newY = widget.widget.layout.y
        var newCols = widget.widget.layout.cols

        // if (this.isDeviceMobile || this.isDeviceTablet) {
        //   newrows = widget.widget.layout.rows <= 5 ? Math.round(widget.widget.layout.rows / 2) : Math.round(widget.widget.layout.rows / 1.5)
        //   newY = widget.widget.layout.y - 5
        // }

        if ((this.isDeviceMobile && window.orientation == 90) || (this.isDeviceTablet && window.orientation == 90)) {
          newCols = Math.round(this.options.maxCols * widget.widget.layout.cols / this.selectedDashboard.gridSize)
        }

        if ((this.isDeviceMobile && window.orientation == 90)) {

          if (newrows > 4) {
            newrows = 4
          }
        }


        var wd = {

          cols: (this.isDeviceMobile) || (this.isDeviceTablet && window.orientation == 0) ? rowcol.cols : newCols,
          // rows: this.isDeviceMobile || (this.isDeviceTablet && window.orientation == 0) ? rowcol.rows : widget.widget.layout.rows,
          rows: (this.isDeviceMobile && window.orientation == 0) || (this.isDeviceTablet && window.orientation == 0) ? this.shrinkRate(widget.widget.layout).rows : newrows,
          y: this.isDeviceTablet && window.orientation == 0 ? currentY : newY,
          x: this.isDeviceTablet && window.orientation == 0 ? (currentX += 6) - 6 : widget.widget.layout.x,
          designerContainer: widget,
        }
        // this.dashboardWidgets.push(wd);

        // this.dashboardWidgets.push(options?.api?.getFirstPossiblePosition(wd));

        this.dashboardWidgets.push(wd);


        this.createWidget(widget);
      }
    })

  }

  shrinkRate(widgetLayout: { x: number, y: number, cols: number, rows: number }) {
    var serviceWidgetsCopy = [...this.serviceWidgets];
    var heightSort = serviceWidgetsCopy.sort((a, b) => a.widget.layout.rows - b.widget.layout.rows);


    if ((this.deviceService.isTablet() && this.isDeviceTablet && window.orientation == 0)) {
      if (widgetLayout.rows == heightSort[0].widget.layout.rows) {
        return { x: widgetLayout.x, y: widgetLayout.y, rows: Math.round(widgetLayout.rows / 2), cols: widgetLayout.cols }
      } if (widgetLayout.rows < heightSort[heightSort.length - 1].widget.layout.rows && widgetLayout.rows > heightSort[0].widget.layout.rows) {
        return { x: widgetLayout.x, y: widgetLayout.y, rows: Math.round(widgetLayout.rows / 1.2), cols: widgetLayout.cols }
      }
      if (widgetLayout.rows == heightSort[heightSort.length - 1].widget.layout.rows) {
        return { x: widgetLayout.x, y: widgetLayout.y, rows: Math.round(widgetLayout.rows / 1.8), cols: widgetLayout.cols }
      }
      else {
        return { x: widgetLayout.x, y: widgetLayout.y, rows: widgetLayout.rows, cols: widgetLayout.cols }
      }
    } else if (this.deviceService.isMobile()) {
      if (widgetLayout.rows == heightSort[0].widget.layout.rows) {
        return { x: widgetLayout.x, y: widgetLayout.y, rows: Math.round(widgetLayout.rows / 6), cols: widgetLayout.cols }
      } else {
        return { x: widgetLayout.x, y: widgetLayout.y, rows: Math.round(widgetLayout.rows / 4), cols: widgetLayout.cols }

      }
    }

    return { x: widgetLayout.x, y: widgetLayout.y, rows: widgetLayout.rows, cols: widgetLayout.cols }
  }


  private getDashboardsWidgets(dashboardId: string) {
    // this.chatbotService.changeRecognitionLang(this.selectedDashboard.contentLanguage)

    this.options.minCols = this.selectedDashboard?.gridSize

    this.options.minRows = this.selectedDashboard?.gridSize

    this.options?.api?.optionsChanged()

    if (this.selectedFilters.length > 0)
      this.scriptLoader.selectedFilters.next(this.selectedFilters)
    else
      this.scriptLoader.selectedFilters.next(this.selectedDashboardInitialFilters)


    var endUserScreen = true
    this.dashboardService.getWidgetsForDesigner(dashboardId, '', endUserScreen).pipe(takeUntil(this.unsubscribe)).subscribe(result => {

      this.serviceWidgets = result
      this.subscribeDashboardChanges(true);
      this.widgetLayoutEdit(this.serviceWidgets, this.options)

    });
  }








  MobileWidgetSettings() {
    const isLandscape = window.innerWidth > window.innerHeight;

    if (this.deviceService.isMobile() || (this.deviceService.isTablet() && !isLandscape)) {
      this.options.gridType = GridType.ScrollVertical
      this.options?.api?.optionsChanged()
    } else {
      this.changeDashboardLayout(this.selectedDashboard.layout)
    }
    if (this.deviceService.isMobile()) {
      this.options.minCols = 10
      this.options.maxCols = 10
      this.options.minRows = 10
      this.options?.api.optionsChanged()
      return { cols: 5, rows: 5 }
    } else if (this.deviceService.isTablet()) {
      if (window.orientation == 0) {
        this.options.minCols = 12
        this.options.maxCols = 12
        this.options.minRows = 12
        this.options?.api.optionsChanged()
      }
      else {
        // this.customizeDashboard()
        this.options.minCols = this.selectedDashboard?.gridSize
        this.options.maxCols = this.selectedDashboard?.gridSize
        this.options.minRows = this.selectedDashboard?.gridSize

        this.options?.api?.optionsChanged()
      }

      return { cols: 6, rows: 6 }
    } else {
      return { cols: 1, rows: 1 }
    }
  }

  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  expandWidget(widgetData: any) {
    let data = {
      widgetData: widgetData.designerContainer,
      shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.selectedFilters }),
      selectedFilters: this.selectedFilters,
      selectedDashboard: this.selectedDashboard
    }
    this.modalService.openModal(data, ExpandedWidgetComponent, '90%', '100%', 'overflow-hidden').subscribe(result => { });
  }

  dashboardSelected(dash: Dashboard) {
    deleteCssFile(this.selectedDashboard.dashboardId)
    // this.selectedDashboard.filter = this.selectedDashboardInitialFilters;


    this.selectedDashboard = dash;

    sessionStorage.setItem('selectedDashboard', JSON.stringify(this.selectedDashboard));
    this.dashboardService.selectedDasboard.next(this.selectedDashboard)
    cerateCssFile(this.selectedDashboard.css, this.selectedDashboard.dashboardId, this.contentIdKeyword)

    this.changeinitFilter()


    let selectedDashboardId = dash.dashboardId;
    this.dashboardService.setLastDashboardId(selectedDashboardId);
    this.dashboardWidgets = [];
    this.selectedFilters = []
    this.selectedFilterGroup = []
    this.subscriptionCheckDashboardInterval.unsubscribe();
    this.changeGridOptions()
    this.getDashboardsWidgets(selectedDashboardId);
    this.showMessages = false;
    this.getMyShares()

  }

  //TO DO-----------------------------------------------------------
  subscribeDashboardChanges(isFirstForCheckDashboardData) {
    if (this.subscriptionCheckDashboardInterval) {
      this.subscriptionCheckDashboardInterval.unsubscribe();
    }
    this.isFirstForCheckDashboardData = isFirstForCheckDashboardData;
    this.checkDashboardInterval = interval(this.selectedDashboard.refreshInterval * 1000);

    this.subscriptionCheckDashboardInterval = this.checkDashboardInterval.subscribe(() => {
      // var currentDate = new Date();

      // // Tarih ve saat bilgisini belirli bir formatta yazdır
      // var formattedDate = currentDate.toLocaleString();
      // console.log('çalıştı',formattedDate)
      this.checkDashboardChanges();
    });
  }

  checkDashboardChanges() {
    this.dashboardService.checkDashboardUpdateTime(this.selectedDashboard.dashboardId).subscribe(res => {
      if (this.isFirstForCheckDashboardData) {
        this.isFirstForCheckDashboardData = false;
        this.dashboardChangesId = res.message;
        return;
      }
      if (this.dashboardChangesId != res.message) {
        // this.dashboardWidgets = [];
        //eğer herhangi bir widgetta değişiklik varsa update time değişiklik olan widgetları dönecek
        if (res?.changedWidgets?.length > 0)
          this.refreshWidget(res.changedWidgets)
        // this.getDashboardsWidgets(this.selectedDashboard.dashboardId);
        this.dashboardChangesId = res.message;
      }
    });
  }

  refreshWidget(changedWidgets) {
    var idlist = []
    // değişen widgetların idsini liste olarak atıroruz servise
    changedWidgets.map(item => {
      idlist.push(item.widgetId)
    })

    this.widgetService.getWidgetById(idlist, this.selectedDashboard.dashboardId, this.selectedDashboard.filter).
      subscribe(res => {
        res.map((item) => {

          var index = this.dashboardWidgets.findIndex(x => x.designerContainer.widgetId == item.widgetId)
          if (index !== undefined) {
            this.dashboardWidgets[index].designerContainer = item
            // this.createWidget(item);
            this.scriptLoader.runWidgetCode(item, true);


          }
        })
      })

  }
  //----------------------------------------------------------------------

  openDashboardFilter() {
    this.isShownFilter = true;
  }


  openViewsModal() {
    var data = {
      myShares: this.myShares,
      applyFilterForViews: (share) => this.applyFilterForViews(share),
      getShares: () => this.getMyShares()
    }
    this.modalService.openModal(data, ViewsComponent, '100%', '100%', 'viewsModal').subscribe(res => {

    })
  }

  applyFilterForViews(share: Share) {
    this.scriptLoader.setFilter(share.filter, false)
  }




  openDashboardFilterAI() {
    this.isShownFilterAI = !this.isShownFilterAI;
  }

  selectedFilters: BussionFilterDefinition[] = []
  selectedFilterGroup: any[] = []
  groupedData: any = {}

  saveFilter(newFilters: BussionFilterDefinition[]) {
    this.closeFilterForm(null);
    this.selectedFilters = newFilters

    this.scriptLoader.selectedFilters.next(newFilters)

    this.getDashboardForFilter(this.selectedDashboard.dashboardId, newFilters);

  }


  clearSelectedFilterGroup(filter) {
    this.selectedFilters = filter
    this.sugesstionFilter = filter
    this.scriptLoader.setFilter(filter, false)
  }



  filterGroup(newFilters: BussionFilterDefinition[]) {
    this.selectedFilters = newFilters

    this.groupedData = {}
    this.selectedFilters.map((item: BussionFilterDefinition, key) => {
      const fieldkey = item['fieldName'];


      if (!this.groupedData[fieldkey]) {
        this.groupedData[fieldkey] = {
          content: [],
          dimension: null,
          priority: this.selectedDashboard.dimensions.findIndex(x => x.name == fieldkey),
          isinitial: this.selectedDashboard.filter.some(x => x.fieldName == fieldkey) ? 1 : 0
        }
      }
      this.groupedData[fieldkey].content.push(item);
      this.groupedData[fieldkey].dimension = this.selectedDashboard.dimensions.find(x => x.name == fieldkey)
    });
    var keys = Object.keys(this.groupedData)
    this.selectedFilterGroup = []
    this.selectedDashboard.dimensions.map(item => {
      if (keys.some(x => x == item.name)) {
        this.selectedFilterGroup.push(item.name)
      }
    })

  }

  resetFilter(event: any) {
    this.changeinitFilter()
    this.sugesstionFilter = []
    this.selectedFilters = []

    this.scriptLoader.setFilter(this.selectedDashboardInitialFilters)
  }

  clearAllFilter(filter: any[]) {
    // this.selectedDashboard.filter = []
    this.sugesstionFilter = []
    this.selectedFilters = []
    this.scriptLoader.setFilter([])
  }

  saveFilterFromShares(newFilters: BussionFilterDefinition[]) {

    this.handleSelectedDashboard()
    this.modalService.dissmissAll()
    this.getDashboardForFilter(this.selectedDashboard.dashboardId, newFilters);

  }

  closeFilterModal(event: any) {
    this.dialog.closeAll()
  }


  closeFilterForm(event: any) {
    this.isShownFilter = false;

  }

  applyDropdownFilter(filter: BussionFilterDefinition[]) {
    this.selectedDashboardInitialFilters = this.selectedDashboard.filter
    this.selectedFilters = []
    filter.map(item => {
      if (!this.selectedFilters.some(x => x.fieldName == item.fieldName && x.fieldValue == item.fieldValue && x.operator == item.operator))
        this.selectedFilters.push(item)
    })



    if (filter.length > 0)
      this.scriptLoader.setFilter(this.selectedFilters, false)
    else if (this.selectedFilters.length <= 0)
      this.scriptLoader.setFilter([], false)

  }

  listenDashboardNavigateEvent() {
    this.scriptLoader.navigateToDashboard.pipe(skip(1)).subscribe(data => {
      this.getDashboardForFilter(data.dashboardId, data.filters);
    });
  }

  listenFilterClick() {
    this.scriptLoader.filterChanges.pipe(skip(1)).pipe(takeUntil(this.unsubscribe)).subscribe(filter => {
      let newFilterList = [];
      this.selectedDashboard.filter.forEach(f => {
        if (filter.findIndex(newF => newF.fieldName == f.fieldName) == -1) {
          newFilterList.push(f);
        }
      });
      newFilterList = [...filter];
      // this.selectedDashboard.filter = newFilterList
      this.selectedFilters = newFilterList
      this.getDashboardForFilter(this.selectedDashboard.dashboardId, this.selectedFilters);
    });
  }

  getDashboardForFilter(dashboardID, filters) {
    console.log('subsciption dışarı')

    if (filters) {
      this.isFilterActive = filters.length > 0;
      // this.selectedDashboard.filter = filters;

      this.dashboardWidgets = [];




      this.dashboardService.getWidgetsForDashboard(dashboardID, filters).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
        console.log('subsciption')
        this.serviceWidgets = result
        this.subscribeDashboardChanges(false);
        this.widgetLayoutEdit(this.serviceWidgets, this.options)
      })
    }
  }


  addActiveDashboard() {
    let data = {
      dashboards: this.authorizedDashboards.filter(x => x.isMainDashboard === 1),
      activeDashboardIds: this.dashboards.filter(x => x.isMainDashboard === 1).map(dash => { return dash.dashboardId })
    }
    this.modalService.openModal(data, DashboardSelectionComponent, 'auto', '600px').subscribe(ids => {

      if (ids) {
        this.addDashboardToActiveDashboardList(ids);
      }
    });
  }
  openSideNav() {
    this.sidenav.toggle()
  }

  private addDashboardToActiveDashboardList(ids: string[]) {
    this.dashboardService.addDashboardToUser(ids).subscribe(result => {
      // this.getDashboards();
      this.getActiveDashboard();
    })
  }
  changeViewList: boolean = false
  openShareForm(shareData: any) {

    let data = {
      objectType: shareData.objectType,
      objectId: shareData.objectId,
      dashboardId: this.selectedDashboard.dashboardId,
      filter: this.selectedFilters,
      function: () => {
        this.changeViewList = !this.changeViewList
      },
    };


    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {
      }
    })
  }



  getMyShares() {
    this.shareService.getMyShare(this.selectedDashboard.dashboardId).subscribe(myShares => {
      this.myShares = myShares;
    })
  }


  headerShown: boolean = true
  showFullScreen(status: boolean) {
    this.isFullscreen = !status;
    this.showMessages = false;
    this.coreService.changeHeaderStatus(status);
    this.headerShown = !this.headerShown
    if (status) {
      this.subscriptionAutoPlayDashboardInterval.unsubscribe();
    }
  }

  changeDashboardLayout(layout: string) {

    if (layout) {
      this.selectedDashboard.layout = layout

      this.options.gridType = GridType[layout];
    } else {
      this.options.gridType = GridType.ScrollVertical
    }
    this.options?.api?.optionsChanged()
  }

  autoPlay() {
    this.isAutoPlaying = true;
    this.subscriptionAutoPlayDashboardInterval = this.autoPlayDashboardInterval.subscribe(() => {
      this.changeDashboard();
    });
  }

  stopAutoPlay() {
    this.isAutoPlaying = false;
    this.subscriptionAutoPlayDashboardInterval.unsubscribe();
  }

  changeDashboard() {
    let index = this.dashboards.indexOf(this.selectedDashboard);
    let nextIndex = index + 1;
    nextIndex = (this.dashboards.length - 1) < nextIndex ? 0 : nextIndex;

    let nextDashboard = this.dashboards[nextIndex];
    this.dashboardSelected(nextDashboard);
  }


  addTextToPdf(pdf, fontSize, font, fontType, text, ypos) {
    const pageWidth = pdf.internal.pageSize.getWidth();
    pdf.setFontSize(fontSize);
    pdf.setFont(font, fontType);
    pdf.setTextColor('#131523');

    var maxWidth = pageWidth - 10;
    var lines = pdf.splitTextToSize(text, maxWidth);

    pdf.text(lines, 10, ypos);

    var lineHeight = fontSize * 0.5 * 0.5;
    var totalHeight = ypos + (lines.length * lineHeight) + 30;
    var newYPosition = totalHeight;

    return newYPosition
  }

  exportAsPdf() {
    const pdf = new jsPDF("portrait", "mm", "a4");

    //font dosyasını ekliyoruz
    pdf.addFileToVFS("Montserrat.ttf", Montserrat);
    pdf.addFileToVFS("MontserratBold.ttf", MontserratBold);
    pdf.addFont("MontserratBold.ttf", "MontserratBold", "bold");
    pdf.addFont("Montserrat.ttf", "Montserrat", "normal");
    pdf.setLanguage('tr')

    let yOffset = 20;
    const pageHeight = pdf.internal.pageSize.getHeight();

    //dashboard title
    yOffset = this.addTextToPdf(pdf, 40, 'MontserratBold', 'bold', this.selectedDashboard.title, yOffset)


    //dashboard desc
    yOffset = this.addTextToPdf(pdf, 20, 'Montserrat', 'normal', this.selectedDashboard.description, yOffset)

    // pdf.setFontSize(20);
    // pdf.setFont('Montserrat', 'normal');
    // pdf.setTextColor('#131523');

    // lines = pdf.splitTextToSize(this.selectedDashboard.description, maxWidth);

    // pdf.text(lines, 10, yOffset);

    // lineHeight = pdf.getFontSize() * 0.8 * 0.8; // Satır yüksekliği (1.2 çarpanı satır boşluğu için)
    // totalHeight = totalHeight + (lines.length * lineHeight);

    // newYPosition = totalHeight;
    // yOffset = yOffset + newYPosition


    const promises = this.dashboardWidgets.map(item => {
      const element = document.getElementById(item.designerContainer.widgetId);
      if (element) {

        return htmlToImage.toPng(element).then(imgData => {
          var imageWidth = (element.clientWidth * 190) / window.innerWidth;
          var imageHeight = (element.clientHeight * imageWidth) / element.clientWidth; // Sayfadaki resim yüksekliği (genişlik 190 mm olacak şekilde ölçekleniyor)

          // Eğer resim mevcut sayfaya sığmazsa yeni sayfa ekle
          if (yOffset + imageHeight > pageHeight - 10) { // 10 mm kenar boşluğu
            pdf.addPage();
            yOffset = 30; // Yeni sayfanın başına yerleştir
          }

          if (imageHeight > pageHeight) {
            imageHeight = pageHeight - 30
          }

          // Resmi ekle ve yOffset'i güncelle
          pdf.setFontSize(20);
          pdf.setFont('MontserratBold', 'bold');
          pdf.setTextColor('#131523');
          pdf.text(item.designerContainer.widget.title, 10, yOffset - 8);

          if (item.designerContainer.widget.subTitle) {
            pdf.setFontSize(10);
            pdf.setFont('Montserrat', 'normal');
            pdf.setTextColor('#131523');
            pdf.text(item.designerContainer.widget.subTitle, 10, yOffset - 4);
          }
          pdf.addImage(imgData, 'PNG', 10, yOffset, imageWidth, imageHeight);
          yOffset += imageHeight + 20; // Resmin altına 20 mm boşluk ekle
        });
      }
    });

    // Tüm Promiseler tamamlandığında PDF'yi kaydedin
    Promise.all(promises).then(() => {
      pdf.save(this.selectedDashboard.title + '.pdf');
    }).catch(error => {
      console.error("Error generating PDF: ", error);
    });
  }

  exportAsPptx() {
    let pptx = new PptxGenJS();
    let slide = pptx.addSlide();

    slide.addText(this.selectedDashboard.title, {
      x: 0.5,
      y: 0.5,
      w: "80%",
      h: 250,
      margin: 0.5,
      fontFace: "Arial",
      fontSize: 34,
      color: "#00000",
      bold: true,
      isTextBox: true,
    });

    slide.addText(this.selectedDashboard.description, {
      x: 0.8,
      y: 1.0,
      w: "80%",
      h: 200,
      margin: 0.5,
      fontFace: "Arial",
      fontSize: 14,
      color: "#00000",
      bold: false,
      isTextBox: true,
    });

    var user = this.authService.getUserFirstName() + ' ' + this.authService.getUserLastName();

    const now = new Date();
    const dateTimeString = now.toLocaleString();

    slide.addText("By " + user, {
      x: 7.4,
      y: 5.3,
      w: "90%",
      h: 200,
      margin: 0.5,
      fontFace: "Arial",
      fontSize: 18,
      color: "#00000",
      bold: false,
      isTextBox: false,
    });

    slide.addText("Generated by Bussion Analytics, Published on : " + dateTimeString, {
      x: 0.5,
      y: 5.5,
      w: "90%",
      h: 200,
      margin: 0.5,
      fontFace: "Arial",
      fontSize: 9,
      color: "#00000",
      bold: false,
      //italic: true,
      isTextBox: false,
    });

    const promises = this.dashboardWidgets.map(item => {
      const element = document.getElementById(item.designerContainer.widgetId);
      if (element) {

        return htmlToImage.toPng(element).then(imgData => {
          let slide = pptx.addSlide();

          var imageWidth = element.clientWidth / 1024
          var imageHeight = element.clientHeight / 768

          slide.addText(item.designerContainer.widget.title, {
            x: 0.5,
            y: 0.5,
            w: "80%",
            h: 100,
            margin: 0.5,
            fontFace: "Arial",
            fontSize: 24,
            color: "#00000",
            bold: true,
            isTextBox: true,
          });

          slide.addText(item.designerContainer.widget.subTitle, {
            x: 0.8,
            y: 1.0,
            w: "80%",
            h: 100,
            margin: 0.5,
            fontFace: "Arial",
            fontSize: 14,
            color: "#00000",
            bold: false,
            isTextBox: true,
          });

          slide.addImage({
            data: imgData,
            x: 0.5,
            y: 1.5,
            // w: `${imageWidth * 100}%`,
            // h: `${imageHeight * 100}%`,
            w: "80%",
            h: "70%"
          });
        });
      }
    });

    // Tüm Promiseler tamamlandığında PDF'yi kaydedin
    Promise.all(promises).then(() => {
      pptx.writeFile({ fileName: this.selectedDashboard.title + ".pptx" });
    }).catch(error => {
      console.error("Error generating PDF: ", error);
    });
  }

  exportDashboard(type: string) {
    if (type == 'pdf') {
      this.exportAsPdf()
    } else if (type == 'pptx') {
      this.exportAsPptx()
    }
    // var capture = document.getElementById('capture')
    // html2canvas(capture).then(canvas => {
    //   this.canvas.nativeElement.src = canvas.toDataURL();
    //   this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
    //   this.downloadLink.nativeElement.download = this.selectedDashboard.title + '.png';
    //   this.downloadLink.nativeElement.click();
    // })
  }

  redirection() {
    if (this.returnApp.observers.length > 0) {
      this.returnApp.emit()
    } else if (this.redirectApp) {
      this.router.navigate([this.redirectApp])
    } else {
      this.router.navigate(['/'])
    }
  }

  openInfoBox() {
    if (this.infoDialogRef) {
      this.infoDialogRef.close(); // Mevcut dialogu kapat
      this.infoDialogRef = null; // Referansı temizle
    }
    var perfWidgets = this.serviceWidgets
    if (this.scriptLoader.openedModals.getValue().length > 0) {
      perfWidgets = this.scriptLoader.openedModals.getValue()[this.scriptLoader.openedModals.getValue().length - 1].widgets
    }
    this.infoDialogRef = this.dialogService.openModalWithRef({ widgets: perfWidgets }, DashboardPerformanceInfoComponent, '50%', '30%', 'right-modal', { bottom: '35px', right: '0px' })
  }


  private customizeDashboard() {
    this.options = {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      margin: this.deviceService.isTablet() && window.orientation != 0 ? 5 : 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 24,
      maxCols: 100,
      minRows: 5,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 205,
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,

    };
  }
}
