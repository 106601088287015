import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GridsterConfig, GridsterItem, GridType, CompactType, DisplayGrid } from 'angular-gridster2';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { Dashboard, Dimension } from 'src/app/models/responses/dashboardResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { interval, Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal/modal.service';
import { DashboardFilterComponent } from 'src/app/pages/manage/dashboard/dashboard-filter/dashboard-filter.component';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { Share } from 'src/app/models/responses/shareResponse';
import { ShareService } from 'src/app/services/share/share.service';
import { WidgetService } from 'src/app/services/widget/widget.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { FilterAiService } from 'src/app/services/filter-ai/filter-ai.service';
import { ExpandedWidgetComponent } from '../modals/expanded-widget/expanded-widget.component';
import { ShareFormComponent } from '../modals/share-form/share-form.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';



@Component({
  selector: 'app-dashboard-preview',
  templateUrl: './dashboard-preview.component.html',
  styleUrls: ['./dashboard-preview.component.scss']
})
export class DashboardPreviewComponent implements OnInit, OnDestroy {


  @Input() set widgets(value: any[]) {


    if (value.length !== 0 && this.dashboard) {
      this.serviceWidgets = value
      this.getDashboardsWidgets(value);
    }
  }
  @Input() window: { width: string, height: string }

  @Input() showExitButton: boolean = false
  @Input() showAvatar: boolean = false
  serviceWidgets: WidgetPreview[] = []



  @Input() dashboard: Dashboard;
  @Input() showFilter: boolean;
  @Input() isShownDashboardTitle: boolean = false;
  @Input() domain: string = null;
  @Input() shareId: string = null;
  @Input() authType: number = 0;
  @Input() shareEncryptKey: string = ''
  @Input() sharedToken: string = ''
  @Input() selectedDashboardInitialFilters: BussionFilterDefinition[] = [];
  isShownFilter: boolean = false;


  options: GridsterConfig;
  dashboardWidgets: Array<GridsterItem> = [];
  opened: boolean;
  checkDashboardInterval = interval(30000);
  subscriptionCheckDashboardInterval: Subscription;
  dashboardChangesId: string = "";
  isDeviceMobile: boolean = false;
  isFirstForCheckDashboardData: boolean = true;
  initialFilter: BussionFilterDefinition[] = []
  selectedFilters: BussionFilterDefinition[] = []
  selectedFilterGroup: any[] = []
  groupedData: {
    [key: string]: {
      content: BussionFilterDefinition[];
      dimension: Dimension;
      priority: number;
      isinitial: number
    }
  } = {}
  uniqTimeStemp: number = 0



  constructor(
    private scriptLoader: ScriptLoaderService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private shareService: ShareService,
    private widgetService: WidgetService,
    private permissionService: PermissionService,
    private filterAIService: FilterAiService,
    private deviceService: DeviceDetectorService,

  ) {
  };


  getUser() {
    if (localStorage.getItem('user'))
      return JSON.parse(localStorage.getItem('user'))
    else
      return 'English'

  }
  ngOnInit(): void {
    this.uniqTimeStemp = Date.now()
    this.customizeDashboard();
    if (this.dashboard.title.includes('{')) {
      var newValue = ''
      let replacedText = this.dashboard.title.replace(/\{([^}]*)\}/, (match, group) => {
        // Burada yerine geçecek yeni değeri belirleyin
        let matchText = this.dashboard.title.match(/\{([^}]*)\}/);
        if (matchText[1] == 'ShowFilter') {
          newValue = this.selectedDashboardInitialFilters[0].fieldValue
        } else if (matchText[1]) {
          var matchedinitfilter = this.selectedDashboardInitialFilters.filter(x => x.fieldValue !== '' && x.fieldName.toLowerCase() == matchText[1].toLowerCase())
          if (matchedinitfilter.length > 0)
            newValue = matchedinitfilter[0].fieldValue;
        }
        return newValue;
      });
      if (newValue.length > 0)
        this.dashboard.title = replacedText
    }

    this.isDeviceMobile = window.innerWidth < 600;

    this.dimensionValuTypeControl()
    this.createPinToFilter(this.selectedDashboardInitialFilters, true)

  }

  ngOnDestroy(): void {
    // Component yok edildiğinde aboneliği sonlandır
    if (this.subscriptionCheckDashboardInterval) {
      this.subscriptionCheckDashboardInterval.unsubscribe();
    }
  }

  dimensionValuTypeControl() {
    this.dashboard.dimensions.map(dim => {
      if (dim.type.includes('String') && dim.value.some(x => typeof (x) !== 'string')) {
        dim.value = dim.value.map(val => String(val));
      } else if (dim.type.includes('Number') && dim.value.some(x => typeof (x) !== 'number')) {
        dim.value = dim.value.map(val => +val);

      }
    })
  }

  changeDashboardLayout(layout: string) {
    if (layout) {
      this.options.gridType = GridType[layout];
    } else {
      this.options.gridType = GridType.ScrollVertical
    }
    this.options?.api?.optionsChanged()

  }



  private getDashboardsWidgets(result: any[]) {


    this.subscribeDashboardChanges(true);


    // if (window.innerWidth > 1024) {
    //   this.options.minCols = this.dashboard?.gridSize;
    //   this.options.minRows = this.dashboard?.gridSize;
    //   this.options?.api.optionsChanged()


    if (this.deviceService.isMobile() || (this.deviceService.isTablet() && screen.orientation.type.includes('portrait'))) {
      this.changeDashboardLayout('ScrollVertical')
    } else {
      this.changeDashboardLayout(this.dashboard.layout)
    }

    //todo
    result = this.addUniqTimeStamp(result)



    result.forEach((widget, key) => {




      if (widget.widget.isFilter == 0) {
        if (this.dashboardWidgets.length > 0) {
          var prevWidget = this.dashboardWidgets[key - 1]
        }
        this.dashboardWidgets.push({
          ...this.widgetLayoutEdit(widget.widget.layout, key, prevWidget),
          designerContainer: widget
        });

        this.createWidget(widget);
      }
    })


    // } else {
    //   this.options.minCols = this.isDeviceMobile ? 1 : 2;
    //   this.options.minRows = Math.ceil(result.length / this.options.minCols);
    //   this.options.defaultItemCols = 2;
    //   this.options.defaultItemRows = 1;

    //   result.forEach((widget, index) => {
    //     if (widget.widget.isFilter == 0) {
    //       this.dashboardWidgets.push({
    //         cols: this.options.defaultItemCols,
    //         rows: this.options.defaultItemRows,
    //         y: Math.floor(index / this.options.minCols),
    //         x: index % this.options.minCols,
    //         designerContainer: widget
    //       });
    //       this.createWidget(widget);
    //     }
    //   });

    // }
  }

  widgetLayoutEdit(widgetLayout: { x: number, y: number, cols: number, rows: number }, sequence: number, prevWidget: { x: number, y: number, cols: number, rows: number }) {
    var serviceWidgetsCopy = [...this.serviceWidgets];
    var heightSort = serviceWidgetsCopy.sort((a, b) => a.widget.layout.rows - b.widget.layout.rows);
    var longest = heightSort[heightSort.length - 1].widget;
    var shortest = heightSort[0].widget;



    var editedWidgetLayout = { x: widgetLayout.x, y: widgetLayout.y, cols: widgetLayout.cols, rows: widgetLayout.rows }
    if (!this.deviceService.isDesktop())
      if (screen.orientation.type.includes('landscape') && this.deviceService.isMobile()) {
        //col ve row değerlerini sabit bir değere çekerek konumve col row değerini daha kolay kontrol edebiliyorun


        this.options.minCols = 12
        this.options.maxCols = 12
        this.options.minRows = 5
        this.options.api.optionsChanged()
        //enküçük widget rowu 2 ye bölünür 1 fazlası alınır

        if (widgetLayout.rows == shortest.layout.rows || widgetLayout.rows <= shortest.layout.rows + 1) {
          editedWidgetLayout.rows = Math.round(editedWidgetLayout.rows / 2)
        } else if (widgetLayout.rows > shortest.layout.rows && widgetLayout.rows < longest.layout.rows) {
          editedWidgetLayout.rows = Math.round(widgetLayout.rows / 1.2)
        }
        //length 1 den büyükse 2 li collara bölecek ama tek widget varsa bunu tamamına yayacak
        if (serviceWidgetsCopy.length > 1) {
          //telefon ve tablette ortak olarak yatay modda col 2 li olacak şekilde dağılır
          editedWidgetLayout.cols = 6
        }

        //kli bldüğümüz için x eksaninde ve y ekseninde yerini değiştirmemiz lazım 2 col a böldüğümüz için mod 2 alıyoruz 
        //ve bir öncekinin konumunu saptamak için prevwidget kullanıyoruz

        if ((sequence + 1) % 2 == 0 && prevWidget) {
          editedWidgetLayout.x = prevWidget.x + 6
          // editedWidgetLayout.y = prevWidget.y + 1
        }

        // var areaSize = window.innerHeight - 50
        // var widgetHeight = widgetLayout.rows * this.options.fixedRowHeight
        // var maxRow = Math.round(areaSize / this.options.fixedRowHeight)
        if (widgetLayout.rows > 4) {
          editedWidgetLayout.rows = 5
        }
      } else {
        if (this.deviceService.isMobile()) {
          //Mobilde portrait durumda en küçük widget 1 tane kolon kapalayacak çünkü fixedheight 205 olarak verildi
          if (widgetLayout.rows == shortest.layout.rows || widgetLayout.rows <= shortest.layout.rows + 1) {
            editedWidgetLayout.rows = 1
          } else if (widgetLayout.rows > shortest.layout.rows && widgetLayout.rows < longest.layout.rows) {
            editedWidgetLayout.rows = Math.round(widgetLayout.rows / 1.2)
          }
          //row 4 ten büyük olunca 4*205 den mobil boyutunu aşıyor boyutu aşanlar ekrana sığacak şekilde küçültülecek
          if (widgetLayout.rows > 4) {
            editedWidgetLayout.rows = 3
          }
        }
        else if (this.deviceService.isTablet()) {

        }
      }

    return editedWidgetLayout
  }



  subscribeDashboardChanges(isFirstForCheckDashboardData) {


    this.isFirstForCheckDashboardData = isFirstForCheckDashboardData;
    this.checkDashboardInterval = interval(this.dashboard.refreshInterval * 1000);

    this.subscriptionCheckDashboardInterval = this.checkDashboardInterval.subscribe(() => {
      this.checkDashboardChanges();
    });
  }



  checkDashboardChanges() {
    this.dashboardService.checkDashboardUpdateTime(this.dashboard.dashboardId, this.domain).subscribe(res => {
      if (this.isFirstForCheckDashboardData) {
        this.isFirstForCheckDashboardData = false;
        this.dashboardChangesId = res.message;
        return;
      }
      if (this.dashboardChangesId != res.message) {
        // this.dashboardWidgets = [];
        if (this.shareId != null) {
          // this.getSharedWidgets();
          if (res?.changedWidgets?.length > 0)
            this.refreshWidget(res.changedWidgets)
        } else {
          // this.fetchDashboardsWidgets(this.dashboard.dashboardId);
          if (res?.changedWidgets?.length > 0)
            this.refreshWidget(res.changedWidgets)
        }
        this.dashboardChangesId = res.message;
      }
    });
  }

  refreshWidget(changedWidgets) {
    var idlist = []
    // değişen widgetların idsini liste olarak atıroruz servise
    changedWidgets.map(item => {
      idlist.push(item.widgetId)
    })

    this.widgetService.getWidgetById(idlist, this.dashboard.dashboardId, this.dashboard.filter).
      subscribe(res => {
        res.map((item) => {

          var index = this.dashboardWidgets.findIndex(x => x.designerContainer.widgetId == item.widgetId)
          if (index !== undefined) {
            this.dashboardWidgets[index].designerContainer = item
            // this.createWidget(item);
            this.scriptLoader.runWidgetCode(item, true);


          }
        })
      })

  }

  // private getSharedWidgets(filters: BussionFilterDefinition[]) {
  //   if (!filters)
  //     filters = this.dashboard.filter
  //   this.shareService.getSharedDashboardWidgets(this.shareId, this.getUser().language, this.domain, filters).subscribe(result => {
  //     result.forEach(widget => {
  //       this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
  //       this.createWidget(widget);
  //     })
  //   })
  // }

  openDashboardFilter() {
    this.isShownFilter = true;
  }

  saveFilter(newFilters: BussionFilterDefinition[]) {

    this.closeFilterForm(null);
    //this.getDashboardForFilter(this.selectedDashboard.dashboardId, newFilters);
    this.dashboard.filter = newFilters;

    this.filterGroup(this.dashboard.filter)
    if (this.shareId) {
      this.getSharedDashboardWidgets(newFilters)
    } else
      this.getWidgetsForFilter(newFilters)
  }

  addUniqTimeStamp(result) {
    this.uniqTimeStemp = Date.now()
    var newresult = result.map(item => {
      if (!item.widgetId.includes('-')) {
        return { ...item, widgetId: this.uniqTimeStemp + '-' + item.widgetId, widget: { ...item.widget, widgetId: this.uniqTimeStemp + '-' + item.widgetId } }
      } else {
        return item
      }
    })

    return newresult;
  }

  getSharedDashboardWidgets(newFilters: BussionFilterDefinition[]) {
    this.dashboardWidgets = [];
    this.shareService.getSharedDashboardWidgets(this.shareId, this.getUser().language, this.domain, newFilters, this.shareEncryptKey, this.authType, this.sharedToken).subscribe(result => {
      result = this.addUniqTimeStamp(result)
      result.forEach((widget, key) => {
        if (widget.widget.isFilter == 0) {

          if (this.dashboardWidgets.length > 0) {
            var prevWidget = this.dashboardWidgets[key - 1]
          }
          // this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
          this.dashboardWidgets.push({
            ...this.widgetLayoutEdit(widget.widget.layout, key, prevWidget),
            designerContainer: widget
          });
          this.createWidget(widget);
        }
      })
    })
  }

  getWidgetsForFilter(newFilters: BussionFilterDefinition[]) {
    this.dashboardWidgets = [];

    this.dashboardService.getWidgetsForDashboard(this.dashboard.dashboardId, newFilters).subscribe(result => {
      result = this.addUniqTimeStamp(result)
      result.forEach((widget, key) => {
        if (widget.widget.isFilter == 0) {


          if (this.dashboardWidgets.length > 0) {
            var prevWidget = this.dashboardWidgets[key - 1]
          }
          // this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
          this.dashboardWidgets.push({
            ...this.widgetLayoutEdit(widget.widget.layout, key, prevWidget),
            designerContainer: widget
          });

          this.createWidget(widget);
        }
      })
    })
  }

  getFilteredDashboard(text: string) {

    this.filterAIService.getSuggestionForDashboard(text, this.dashboard.dashboardId).subscribe((res) => {
      this.selectedDashboardInitialFilters = this.selectedDashboardInitialFilters.concat(this.scriptLoader.selectedFilters.getValue())

      this.selectedFilters.map(filter => {
        if (!this.selectedDashboardInitialFilters.some(x => x.fieldValue == filter.fieldValue && x.fieldName == filter.fieldName))
          this.selectedDashboardInitialFilters.push(filter)
      })

      var suggestfilter = res[0].dashboard.filter
      suggestfilter.map(filter => {
        if (!this.selectedDashboardInitialFilters.some(x => x.fieldValue == filter.fieldValue && x.fieldName == filter.fieldName))
          this.selectedDashboardInitialFilters.push(filter)
      })

      this.selectedDashboardInitialFilters = this.selectedDashboardInitialFilters.filter(x => x.fieldValue !== '')

      this.filterGroup(this.selectedDashboardInitialFilters)

      this.getWidgetsForFilter(this.selectedDashboardInitialFilters)
      // this.scriptLoader.SetFilterForDashboard(this.dashboard.dashboardId, this.selectedDashboardInitialFilters)


    });

  }

  applyDropdownFilter(filter: BussionFilterDefinition[]) {
    if (filter.length > 0) {
      if (this.shareId) {
        this.getSharedDashboardWidgets(filter)
        // this.dashboardWidgets = []
        // this.shareService.getSharedDashboardWidgets(this.shareId, this.getUser().language, this.domain, filter, this.shareEncryptKey, this.authType, this.sharedToken).subscribe(result => {
        //   result.forEach(widget => {
        //     if (widget.widget.isFilter == 0) {
        //       this.dashboardWidgets.push({ cols: widget.widget.layout.cols, rows: widget.widget.layout.rows, y: widget.widget.layout.y, x: widget.widget.layout.x, designerContainer: widget });
        //       this.createWidget(widget);
        //     }
        //   })
        // })

        this.selectedFilters = this.selectedFilters.concat(filter.filter(item =>
          !this.selectedFilters.some(x => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue)
        ))



      } else {
        // this.scriptLoader.SetFilterForDashboard(this.dashboard.dashboardId, filter)
        this.selectedFilters = this.selectedFilters.concat(filter)
        this.getWidgetsForFilter(filter)
      }

    }

  }

  clearSelectedFilterGroup(filter) {

    this.selectedFilters = filter
    this.filterGroup(this.selectedFilters)

    this.createPinToFilter(this.selectedDashboardInitialFilters, false)

    if (this.shareId) {
      this.getSharedDashboardWidgets(filter)
    } else
      this.getWidgetsForFilter(filter)

    // this.scriptLoader.SetFilterForDashboard(this.dashboard.dashboardId, filter)
  }


  clearAllFilter(filterlist: any[]) {
    this.filterGroup(this.initialFilter)

    var filter = []

    if (this.initialFilter.length > 0) {
      var editinitfilter = this.initialFilter.filter(x => x.fieldValue !== '')
      filter = editinitfilter
    } else {
      filter = []
    }

    // this.scriptLoader.SetFilterForDashboard(this.dashboard.dashboardId, filter)

    if (this.shareId) {
      this.getSharedDashboardWidgets(filter)
    } else
      this.getWidgetsForFilter(filter)
  }

  createPinToFilter(initfilter: BussionFilterDefinition[], first: boolean) {
    var selecteddimensiontofilter: Dimension[] = this.dashboard.dimensions.filter(x => x.pintoPanel == 1)
    var bsf: BussionFilterDefinition[] = selecteddimensiontofilter.map(item => {
      return {
        fieldName: item.name,
        operator: '',
        fieldValue: ''
      }
    })
    if (first)
      this.initialFilter = initfilter.concat(bsf)
    this.filterGroup(initfilter.concat(bsf))
  }

  filterGroup(newFilters: BussionFilterDefinition[]) {
    this.selectedFilters = newFilters
    this.selectedDashboardInitialFilters = newFilters
    this.groupedData = {}
    this.selectedFilters.map((item: BussionFilterDefinition, key) => {
      const fieldkey = item['fieldName'];

      if (!this.groupedData[fieldkey]) {
        this.groupedData[fieldkey] = {
          content: [],
          dimension: null,
          priority: this.dashboard.dimensions.findIndex(x => x.name == fieldkey),
          isinitial: this.dashboard.filter.some(x => x.fieldName == fieldkey) ? 1 : 0
        }
      }
      this.groupedData[fieldkey].content.push(item);
      this.groupedData[fieldkey].dimension = this.dashboard.dimensions.find(x => x.name == fieldkey)
    });
    var keys = Object.keys(this.groupedData)
    this.selectedFilterGroup = []
    this.dashboard.dimensions.map(item => {
      if (keys.some(x => x == item.name)) {
        this.selectedFilterGroup.push(item.name)
      }
    })

  }


  expandWidget(widgetData: any) {
    let data = {
      widgetData: widgetData.designerContainer,
      shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.selectedFilters }),
      selectedFilters: this.selectedFilters,
      selectedDashboard: this.dashboard
    }
    this.modalService.openModal(data, ExpandedWidgetComponent, '90%', '100%', 'overflow-hidden').subscribe(result => { });
  }

  openShareForm(shareData: any) {
    let data = {
      objectType: shareData.objectType,
      objectId: shareData.objectId,
      dashboardId: this.dashboard.dashboardId,
      filter: this.dashboard.filter,
      function: () => { }
    };

    this.modalService.openModal(data, ShareFormComponent, 'auto', '600px').subscribe(result => {
      if (result) {
      }
    })
  }

  closeFilterForm(event: any) {
    this.isShownFilter = false;
  }

  private createWidget(widgetResponse: any) {
    this.scriptLoader.addScriptFileToHead(widgetResponse.widgetLibraries);
    this.scriptLoader.runWidgetCode(widgetResponse);
  }

  private customizeDashboard() {

    var gridtype = this.dashboard.layout ? GridType[this.dashboard.layout] : GridType.ScrollVertical
    this.options = {
      itemChangeCallback: (opened) => {
        this.options?.api.optionsChanged()
      },
      gridType: gridtype,
      compactType: CompactType.CompactUp,
      margin: (this.deviceService.isTablet() && window.orientation != 0) || this.deviceService.isMobile() ? 5 : 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 600,
      minCols: 2,
      maxCols: 100,
      minRows: this.dashboardWidgets.length,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 205,
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };
  }

}