import { Component, OnInit, ElementRef, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChatbotService } from 'src/app/services/chatbot/chatbot.service';
import { environment } from 'src/environments/environment';
import { DashboardOpenerComponent } from '../dashboard-opener/dashboard-opener.component';
import { SpeechProcces } from 'src/app/core/speechTypes';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { Overlay } from '@angular/cdk/overlay';

export class AIDashboardSuggestion {
  public dashboard: Dashboard
  public message = "";
  public weight = 0;
  public percentage = 0;
  public isAccessable = 1;
}

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, OnDestroy {
  @ViewChild('chatListContainer') chatListContainer?: ElementRef<HTMLDivElement>;
  @ViewChild('chatInput') chatInput?: ElementRef<HTMLInputElement>;

  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;

  chatInputMessage: string = "";
  human = {
    id: 1,
    name: localStorage.getItem('name') ?? 'Visitor',
    profileImageUrl: environment.imageUrl + localStorage.getItem('avatar')
  }

  permissions: string[] = []
  licencePermissions = localStorage.getItem('licence') ?? ''



  bot = {
    id: 2,
    profileImageUrl: "../../../assets/images/logoAI.png"
  }

  chatMessages: {
    user: any,
    message: any
  }[] = [];
  suggestionDashboard: Dashboard[] = []
  filteredSuggestions: AIDashboardSuggestion[] = [];
  selectedPage: any[] = []
  baseUrl: string = environment.baseUrl
  avatar: string = "";

  functionList: { type: number, function: Function }[] = [
    { type: SpeechProcces.TextToChatBot, function: (text) => this.setChatMessage(text) },
    { type: SpeechProcces.Delete, function: (text) => this.clearChat() }
  ]
  micenable: boolean = true
  chatVisible: boolean = false

  constructor(
    private chatbotService: ChatbotService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private dialogService: ModalService,
    private translate: TranslateService,
    private scriptLoader: ScriptLoaderService,
    private overlay: Overlay
  ) { }

  ngOnInit(): void {
    this.permissions = this.authService.getPermissions()
   
    this.getChatbotMessage();
    this.avatar = environment.imageUrl + this.authService.getUserAvatar();
    this.getStoredMessages();
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      this.micenable = true
    } else {
      this.micenable = false
    }

  }

  ngOnDestroy(): void {
    this.scriptLoader.selectedFilters.next([])
  }

  ngAfterViewChecked() {
    this.scrollToBottom()
  }
  loadingMessage: Boolean = false

  getChatbotMessage() {
    if (this.chatInputMessage.length === 0) {
      return
    }
    else {


      const userMessage = {
        user: this.human,
        message: this.chatInputMessage
      };
      this.chatMessages.push(userMessage);
      var tempmessage = this.chatInputMessage
      this.chatInputMessage = '';
      this.loadingMessage = true
      this.chatbotService.getSuggesstion(tempmessage).subscribe((result: AIDashboardSuggestion[]) => {
        this.loadingMessage = false
        const botMessage: {
          message: AIDashboardSuggestion[] | string,
          user: {
            id: number,
            profileImageUrl: string
          }
        } = {
          user: this.bot,
          message: result
        };
        if (result) {


          if (typeof botMessage.message !== 'string') {
            this.suggestionDashboard = result.map(item => { return item.dashboard })
            botMessage.message = botMessage.message.sort((a, b) => b.percentage - a.percentage);
          } else {
            botMessage.message = (botMessage.message as string).replace(/\n/g, '<br>').replace(/\t/g, '  ');
          }

        } else {
          if (botMessage.message == '') {
            botMessage.message = this.translate.instant('Common.ChatBotMessage')
          }

        }

        this.chatMessages.push(botMessage);

        localStorage.setItem('chatMessages', JSON.stringify(this.chatMessages));
        this.chatInputMessage = '';
        this.scrollToBottom();
      });
    }
  }



  setChatMessage(text: string) {

    this.chatInputMessage = text
    this.chatInput.nativeElement.focus()
    this.getChatbotMessage();
    if (this.suggestionDashboard.length > 0 && this.suggestionDashboard.some(x => x.title.toLowerCase().includes(text.toLowerCase()))) {
      var selectedDashboard = this.suggestionDashboard?.find(x => x.title.toLowerCase().includes(text.toLowerCase()))
      if (selectedDashboard) {
        this.router.navigate(["/main"], { state: selectedDashboard });
        this.closeChatBot()
      }

    }
  }

  stringfyer(filter) {
    return JSON.stringify(filter)
  }

  getStoredMessages() {
    const storedMessages = localStorage.getItem('chatMessages');
    if (storedMessages) {
      this.chatMessages = JSON.parse(storedMessages);
    }
  }

  scrollToBottom() {
    const maxScroll = this.chatListContainer?.nativeElement.scrollHeight;
    this.chatListContainer?.nativeElement.scrollTo({ top: maxScroll, behavior: 'smooth' });
  }

  generateFakeId(): string {
    const current = new Date();
    const timestamp = current.getTime();
    return timestamp.toString()
  }

  addedModalList(addedfilters) {
    const currentModals = this.scriptLoader.openedModals.getValue();
    var currentIndex = currentModals.length > 0 ? currentModals[currentModals.length - 1].modal + 1 : 1

    var currentModalFilter = []

    currentModalFilter = addedfilters



    this.scriptLoader.changeModalList(currentModalFilter, currentIndex)
  }

  openDashboard(dashboard: any, filter: any) {
    this.scriptLoader.selectedFilters.next(filter)
    this.addedModalList(filter)
    this.dialogService.openModal({
      url: '/dashboard-opener/' + dashboard.dashboardId + '?' + this.stringfyer(filter),
      filter: filter,
      param: dashboard.dashboardId,
      drag: false
    }, DashboardOpenerComponent, '100%', '100%', 'overflow-hidden').subscribe(res => {
      this.scriptLoader.openedModals.getValue().pop()
    })
    // this.dialog.open(DashboardOpenerComponent, {
    //   width: '100%',
    //   height:'100%',
    //   data: {
    //     url: '/dashboard-opener/' + dashboard.dashboardId + '?' + this.stringfyer(filter),
    //     filter: filter,
    //     param: dashboard.dashboardId,
    //     drag: false
    //   },
    // });

  }

  closeChatBot() {
    this.dialog.closeAll()
  }

  clearChat() {
    this.chatMessages = [];
    localStorage.removeItem('chatMessages');
  }


  focusChatInput(message: string) {
    this.chatInputMessage = message;
    if (this.chatInput) {
      this.chatInput.nativeElement.focus();
      this.chatInput.nativeElement.select();
    }
  }


  openDialog(): void {
    this.dialogRef = this.dialog.open(this.modalTemplate, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '30%',
      height: '70%',
      enterAnimationDuration: '0ms', // Açılırken animasyonu devre dışı bırak
      exitAnimationDuration: '0ms',
      panelClass: 'chat-container',

    });

   
  }

  closeDialog(): void {
    this.dialogRef.close();

  }

}