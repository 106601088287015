<!-- <div class="dashboard-header" *ngIf="isShownDashboardTitle||showFilter">
    <div class="dashboard-title" *ngIf="isShownDashboardTitle">
        <span class="title-text">{{dashboard.title}}</span>
    </div>
    <div class="dashboard-button-groups">
        <button *ngIf="showFilter" class="button" mat-button
            (click)="openDashboardFilter()">
            {{ 'Common.Filters' | translate }}
            <mat-icon svgIcon="filter"></mat-icon>
        </button>
    </div>
</div> -->
<app-dashboard-header [selectedFilterGroup]="selectedFilterGroup" [groupedData]="groupedData"
    (getFilteredDashboardFunc)="getFilteredDashboard($event)" (openDashboardFilterFunc)="openDashboardFilter()"
    (resetFilterFunc)="clearAllFilter($event)" [disableDashboardList]="true" [disableFilter]="!showFilter"
    [selectedDashboard]="dashboard" [disableMessages]="true" [disableFit]="true" [disableViews]="true"
    (clearSelectedFilterGroup)="clearSelectedFilterGroup($event)" [disableAvatar]="!showAvatar"
    (applyDropdownFilter)="applyDropdownFilter($event)" [selectedFilters]="selectedFilters"
    [closeButton]="showExitButton">
    <div class="exit-button" close-button *ngIf="showExitButton">
        <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
</app-dashboard-header>

<div class="filter-contentx" *ngIf="isShownFilter">
    <app-filter-selection [dimensions]="dashboard.dimensions" [filters]="selectedFilters"
        [initialFilters]="selectedDashboardInitialFilters" (save)="saveFilter($event)" (close)="closeFilterForm($event)"
        [isHeaderShown]="false" [savebuttonvisible]="authType==0?false:true">
    </app-filter-selection>
</div>

<div class="grid-container"
    [ngStyle]="{'height':showFilter&&selectedFilterGroup.length>0?'calc(100% - 90px)': 'calc(100% - 50px)' }">
    <gridster [options]="options">
        <gridster-item [item]="item" *ngFor="let item of dashboardWidgets">
            <app-widget-preview style="height: 100%;" [showZoom]="true" [showShare]="true" [widget]="item"
                (expandEvent)="expandWidget($event)" (shareEvent)="openShareForm($event)"
                [selectedDashboard]="dashboard" class="gridster-item" [selectedFilters]="selectedFilters"
                [authType]="authType">
            </app-widget-preview>

        </gridster-item>
    </gridster>
</div>