<div>
  <button (click)="openDialog()" class="chat-button"
    *ngIf="permissions.includes('AIUser')&&licencePermissions.includes('Bussion AI')">
    <mat-icon svgIcon="messagebot"></mat-icon>
  </button>
  <ng-template #modalTemplate>
    <div>


      <!-- [ngClass]="{'show': chatVisible}" -->
      <div class="chat-header">
        <mat-icon svgIcon="messagebot"></mat-icon>
        <div class="messagebot-title">Data Assistant</div>
      </div>
      <!-- <div class="caption" style="display: flex;align-items: center;justify-content: space-between;margin-top: 1rem;">
      <div style="display: flex;align-items: center;justify-content: center;">
        <img decoding="async" id="thumbnail" src="../../../assets/images/logoAI.png">
  
        <h3 style="font-size: large;font-weight: 200;margin: 0px;">
          <span style="font-weight: bold;">{{"Common.chatbot"|translate}}</span>
        </h3>
      </div>
  
      <mat-icon (click)="closeChatBot()" mat-dialog-close>clear</mat-icon>
  
    </div>
  
    <div class="container" style="height:100%">
      <div class="card">
        <div class="chat-list" #chatListContainer>
          <ng-container *ngFor="let item of chatMessages">
            <div *ngIf="!item.message[0]?.dashboard" class="chat-item"
              [ngClass]="{'right' : item?.user?.id == human.id, 'left': item?.user?.id != human.id}"
              (dblclick)="focusChatInput(item.message)">
              <img id="avatar" [src]="item?.user?.profileImageUrl">
              <p [innerHTML]="item?.message" *ngIf="!item.dashboard" id="message-body"></p>
            </div>
            <ul class="card-wrapper" *ngIf="item.message[0]?.dashboard">
              <li class="cardDash" *ngFor="let suggestion of item.message">
                <img src='{{baseUrl}}{{suggestion.dashboard?.icon}}' alt=''
                  (click)="openDashboard(suggestion.dashboard, suggestion.dashboard.filter)">
                <div>{{ suggestion.dashboard.title }}</div>
                <p style="font-weight: bold;">{{ suggestion.message }}</p>
              </li>
            </ul>
          </ng-container>
        </div>
        <div *ngIf="loadingMessage" style="margin-left: 3rem;margin-bottom: 0.3rem;" class="snippet"
          data-title="dot-falling">
          <div class="stage">
            <div class="dot-falling"></div>
          </div>
        </div>
        <div class="chat-footer">
          <div class="input-area">
            <input style="width: 100%;font-size: inherit;" matInput id="chat-textarea" #chatInput
              (keyup.enter)="getChatbotMessage()" [(ngModel)]="chatInputMessage" required>
            <div class="icon-area">
              <app-speech-microphone *ngIf="micenable" [activeAssistan]="true"
                [functionList]="functionList"></app-speech-microphone>
              <mat-icon [class]="loadingMessage?'send-icon btn-disable':'send-icon'"
                (click)="!loadingMessage? getChatbotMessage():''" [disabled]="chatInputMessage === ''">send</mat-icon>
            </div>
          </div>
          <span class="clearChat" (click)="clearChat()">Clear all chat..</span>
  
        </div>
      </div> -->
    </div>
  </ng-template>
</div>